<template>
  <LoginScreen :confirmed-email="!!(confirmedEmail ?? '')" />
</template>
<script setup lang="ts">
/* eslint-disable no-undef */
useHead({
  title: 'Авторизация в TeleAds.',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content:
        'Войти в личный кабинет TeleAds через Telegram или email. Запускайте рекламу в популярном месенджере Telegram через TeleAds.',
    },
  ],
});

const {
  query: { confirmedEmail },
} = useRoute();
</script>
