<template>
  <div class="screen-wrapper auth">
    <h1 v-if="context !== LoginScreenContext.order">Войти в Data light</h1>
    <LoginEmailAuthorize
      v-if="[LoginMode.AUTH_EMAIL, LoginMode.REGISTER_EMAIL].includes(mode)"
      :context="context"
      :mode="mode"
      @authorized="$emit('authorized', $event)"
    />
    <LoginTelegramAuthorizeForm
      v-if="mode === LoginMode.AUTH_TELEGRAM"
      :context="context"
      @set-mode="setMode"
      @authorized="$emit('authorized', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import useSetState from '~/composables/UI/useSetState';
import { LoginMode, LoginScreenContext } from '~/interfaces/login/UI';

/* eslint-disable no-undef */

interface Props {
  context?: LoginScreenContext;
  confirmedEmail?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  context: LoginScreenContext.null,
  confirmedEmail: undefined,
});
defineEmits(['authorized']);

const [mode, setMode] = useSetState(
  props.confirmedEmail ? LoginMode.AUTH_EMAIL : LoginMode.AUTH_TELEGRAM,
);
</script>
<style lang="scss">
.auth {
  transition: none;
  width: 30rem;
  margin: auto;
  background: white;
  min-height: auto;
  padding: 4rem 0rem;
  align-self: center;
  margin-top: 4rem;
  border-radius: 2rem;
  border: 1px solid #17dbff80;

  &,
  .col-gap-3 {
    justify-content: center;
    align-items: center;

    label {
      align-self: baseline;
    }
  }

  .col-gap-3 .col-gap-3 {
    max-width: 370px;
  }

  h1 {
    margin-bottom: 2rem;
  }

  .auth-form {
    input {
      width: 100%;
    }

    .button-secondary {
      width: 100%;
    }
  }

  @media (max-width: 578px) {
    span {
      width: fit-content;
    }
  }
}

.authorize-modal {
  .el-dialog {
    --el-dialog-border-radius: 16px;
  }

  .screen-wrapper {
    max-width: fit-content;
    border: none;
    padding: 1rem;
    margin: auto;

    @media (max-width: 578px) {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
